import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./screens/Home";
import Portfolio from "./screens/Portfolio";
import Header from "./components/header";
import Navbar from "./components/navbar";
import Explore from "./screens/Explore"
import Contact from "./screens/contact";
import NotFound from "./components/NotFound";

export default function App() {
  return (

    <>
      <Header />
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/about" element={<Portfolio />} />
          <Route path="/explore" element={<Explore />} />
          <Route path="/contact" element={< Contact/>} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter></>
  );
}
