import React from 'react';
const Projects = () => {
    // Array of project data
    const projectData = [
        {
            id: 1,
            image: 'https://buymeasamosa.arycodes.in/assets/images/logo_512.png',
            title: 'Buy Me A Samosa',
            description: 'Buy Me A Samosa is a simple tool for Indian creators. It helps them get support from their viewers easily through UPI.',
            link: 'https://buymeasamosa.arycodes.in',
        },
        {
            id: 2,
            image: 'https://ezlynk.vercel.app/assets/logo.png',
            title: 'EZLynk',
            description: 'EZLynk is a quick link shortener and URL manager powered by Flask and Firebase for efficient link management',
            link: 'https://ezlynk.vercel.app',
        },
        {
            id: 3,
            image: 'https://myarybot.web.app/logo2.png',
            title: 'AryBot',
            description: "AryBot-2 is an enhanced one-page React JS app with Google's Generative API, offering improved appearance, options, and personalization.",
            link: 'https://myarybot.web.app',
        },
        {
            id: 4,
            image: 'https://arybot2.vercel.app/assets/logo.png',
            title: 'AryBot',
            description: "AryBot is an AI chatbot crafted with Python Flask and Google's Generative API, delivering natural and engaging conversation experiences.",
            link: 'https://arybot2.vercel.app',
        },
        {
            id: 5,
            image: 'https://arycodes.in/assets/logo.png',
            title: 'AryCodes',
            description: 'AryCodes is my practice of providing content to learn and practice projects on different topics.',
            link: 'https://arycodes.in',
        },
        // Add more projects here
    ];

    return (
        <div className="cardcontainer projects">
            {projectData.map(project => (
                <div className="projectcontainer" id={`project${project.id}`} key={project.id}>
                    <div className="proj-p1">
                        <div className="project_img">
                            <img src={project.image} alt={project.title} />
                        </div>
                    </div>
                    <div className="proj-p2">
                        <div className="project_title">
                            <h3>{project.title}</h3>
                        </div>
                        <div className="project_desc">
                            <p><strong>{project.title}</strong> {project.description}</p>
                        </div>
                        <div className="project_viewbtn">
                            <p onClick={() => window.open(project.link, '_blank')}>View Live</p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Projects;
