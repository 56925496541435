import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./home-projects-repos.css"

function HomeRepos() {
    const [repos, setRepos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const username = 'arycodes';

    useEffect(() => {
        const fetchRepos = async () => {
            setLoading(true);
            setError('');
            try {
                const response = await axios.get(`https://repoarycodes.vercel.app/repos/${username}`);
                setRepos(response.data.slice(0, 10)); // Slice the first 10 repos
            } catch (err) {
                setError('Failed to fetch data');
            }
            setLoading(false);
        };

        fetchRepos();
    }, [username]);

    return (
        <div className="repoHomeContainer">
            {loading && <p className="repoHomeLoading">Loading...</p>}
            {error && <p className="repoHomeError">{error}</p>}
            <div className="repoHomeList">
                {repos.map((repo) => (
                    <div key={repo.name} className="repoHomeItem">
                        <h2><a href={repo.url} target="_blank" rel="noopener noreferrer">{repo.name}</a></h2>
                        <p className='repoHomeDescription'>{repo.description}</p>
                        <p className='repoHomeLanguages'><strong>Languages used:</strong> {Object.keys(repo.languages).join(', ')}</p>
                        <p className='repoHomeVisit'><a href={repo.url} target="_blank" rel="noopener noreferrer">Visit repo: {repo.name}</a></p>
                        {/* <p><strong>Stars:</strong> {repo.stargazers_count}</p>
              <p><strong>Forks:</strong> {repo.forks_count}</p>
              <p><strong>Open Issues:</strong> {repo.open_issues_count}</p> */}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default HomeRepos;
