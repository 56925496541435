import React from "react";
import "./home-projects-repos.css";

const projects = [
    {
        name: "Buy Me A Samosa",
        image: "https://buymeasamosa.arycodes.in/assets/images/logo_512.png",
        description: "Buy Me A Samosa is a simple tool for Indian creators. It helps them get support from their viewers easily through UPI.",
        link: "https://buymeasamosa.arycodes.in"
    },
    {
        name: "EZLynk",
        image: "https://ezlynk.vercel.app/assets/logo.png",
        description: "EZLynk is a quick link shortener and URL manager powered by Flask and Firebase for efficient link management",
        link: "https://ezlynk.vercel.app"
    },
    {
        name: "AryBot-2",
        image: "https://myarybot.web.app/logo2.png",
        description: "AryBot-2 is Enhanced one-page React JS app with Google's Generative API, offering improved appearance, options, and personalization.",
        link: "https://myarybot.web.app"
    },
    {
        name: "AryBot",
        image: "https://arybot2.vercel.app/assets/logo.png",
        description: "AryBot is an AI chatbot crafted with Python Flask and Google's Generative API, delivering natural and engaging conversation experiences.",
        link: "https://arybot2.vercel.app"
    },
    {
        name: "AryCodes",
        image: "https://arycodes.in/assets/logo.png",
        description: "AryCodes is my practice of providing content to learn and practice projects on different topics.",
        link: "https://arycodes.in"
    }
];

const HomeProjects = () => {
    return (
        <div className="carousel-container">
            <div className="carousel">
                {projects.map((project, index) => (
                    <div className="carousel-container-card" key={index}>
                        <div className="carousel-card">
                            <img src={project.image} alt={project.name} />
                            <div className="project_info">
                                <h3>{project.name}</h3>
                                <p>{project.description}</p>
                                <p className="lnk" onClick={() => window.open(project.link, '_blank')}>View Live</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HomeProjects;
