import React from 'react';
import "./home.css"
import HomeProjects from '../components/home-projects';
import HomeRepos from '../components/home-repos';
const Home = () => {
  const handleMailClick = (event) => {
    event.preventDefault();
    window.open("https://mailhide.io/e/iuBWdtqU", "_blank", "noopener,noreferrer");
  };
  return (
    <div className='container-main main'>
      <h2 id='greet' className='msg'>Namaste <i className="fas fa-praying-hands"></i></h2>
      <p className='msg one'>I'm <strong>Aryan</strong> , and I love to code!</p>
      <p className='msg two'>I'm an Indian Student who enjoys creating computer programs and apps.  I also love coming up with new ideas</p>
      <div className="social-cont">
        <a href="https://www.github.com/arycodes" target="_blank" rel="noreferrer" className="social-icon">
          <i className="fab fa-github icon"></i>
        </a>
        <a href="https://www.instagram.com/arycodes" target="_blank" rel="noreferrer" className="social-icon">
          <i className="fab fa-instagram icon"></i>
        </a>
        <a href="https://arycodes.in" target="_blank" rel="noreferrer" className="social-icon">
          <i className="fas fa-globe icon"></i>
        </a>
        <a href="https://mailhide.io/e/iuBWdtqU" className="social-icon" onClick={handleMailClick}>
          <i className="fas fa-envelope icon"></i>
        </a>
      </div>


      <div className="container-secondary">
        <h2 className='sub-head'>My recent projects</h2>
        <HomeProjects />
        <a className='view-all' href="/portfolio">view all</a>
      </div>

      <div className="container-secondary">
        <h2 className='sub-head'>Recent Repopsitories </h2>
        <HomeRepos />

        <a className='view-all' href="/portfolio">view all</a>
      </div>


    </div>
  );
};

export default Home;
