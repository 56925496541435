import React from 'react';

import "./portfolio.css"

import Tools from '../components/Tools';
import Projects from '../components/Projects';

const Portfolio = () => {

  return (
    <div className='container-main main'>

      <div className="container-name">
        <div className="namecontainer-in">
          <h2>Aryan Mishra</h2>
          <p>Computer Science Student</p>
          <div className="avatarcontainer">A </div>
        </div>

      </div>

      <h2>Tools I uses</h2>

      <Tools />

      <h2>Projects I Made</h2>
      <Projects />

    </div>

  );
};

export default Portfolio;
