// NotFound.js
import React from 'react';

const NotFound = () => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '90vh',
      width:"90%",
      margin:"0 auto",
      backgroundColor: '#fff',
      color: '#333',
      textAlign: 'center',
      fontFamily: 'Arial, sans-serif'
    }}>
      <h1 style={{
        fontSize: '6em',
        margin: '0',
        color: '#555'
      }}>404</h1>
      <h2 style={{
        fontSize: '2em',
        margin: '10px 0',
        color: '#666'
      }}>Page Not Found</h2>
      <p style={{
        fontSize: '1.2em',
        color: '#888',
        maxWidth: '600px'
      }}>
Oops! The page you're searching for isn't here. It may have been moved, renamed, or never existed.      </p>
      <a href="/" style={{
        marginTop: '20px',
        padding: '10px 20px',
        borderRadius: '5px',
        backgroundColor: '#e0e0e0',
        color: '#333',
        textDecoration: 'none',
        transition: 'background-color 0.3s'
      }} onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#d5d5d5'}
        onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#e0e0e0'}>
        Return to Home
      </a>
    </div>
  );
};

export default NotFound;
