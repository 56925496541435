import React from "react";
import "./explore.css"
import Repos from "../components/Repos";
const Explore = () =>{
    return(
        <div className="container-main main">
            <h2>Explore the Repos</h2>
            <Repos/>
        </div>
    )
}


export default Explore