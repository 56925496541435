import React from "react";
import "./header.css"

const Header = () => {
    return (
      <div className="header">
        <div className="logo-cont">
            {/* <h1>Aryan</h1> */}
        </div>
      </div>
    );
  };
  
  export default Header;